<template>
  <div class="">
    <div class="tableConTop">
      <el-row>
        <el-col :span="6" class="tableConTopLeft">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>{{$t('i18nn_ec1a4c45b0b20622')}}</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="10" class="tableConTopCenter">
          <!-- <el-button type="primary" plain icon="el-icon-upload" size="small" @click="openDioalog(null)">{{$t('i18nn_ff6fd823ffab7e6b')}}</el-button>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null)">{{$t('c0246c55b9cac963')}}</el-button> -->
          <el-button-group>
            <el-button type="primary" size="small" @click="toPageName('PermConfAdmin')">{{$t('i18nn_5cabbc13e4bee21a')}}</el-button>
            <el-button type="primary" plain size="small" @click="toPageName('PermConfPerm')">{{$t('i18nn_e40dab1482ecbe86')}}</el-button>
          </el-button-group>
        </el-col>
        <el-col :span="8" class="tableConTopRig">
          <!-- <el-button type="primary" plain icon="el-icon-upload" size="small" @click="openDioalog(null)">{{$t('i18nn_ff6fd823ffab7e6b')}}</el-button> -->
          <el-button type="primary" icon="el-icon-plus" size="small" @click="toPageNameParm('PermConfAdminAdd',null)">{{$t('c0246c55b9cac963')}}</el-button>
        </el-col>
      </el-row>
      
    </div>
    <div class="tableCon" v-loading="loading_load" :element-loading-text="$t('i18nn_b954f8829728e9d2')">
      
      <div class="tableConTable">
        <el-table :height="$store.state.tableMaxHeight" 
        :data="tableData" 
         :max-height="$store.state.tableMaxHeight"
        style="width: 100%">
          
          <el-table-column
          align="center"
            type="index" :index="$Utils.tableIndex(pagination)"
            width="55">
          </el-table-column>
          <el-table-column prop="userId" label="userId" width="200px">
          </el-table-column>
          <el-table-column prop="userName" :label="$t('i18nn_76c9af09324c78c7')">
          </el-table-column>
          <el-table-column prop="lastLoginTime" :label="$t('i18nn_55e9328f2e63cea3')">
          </el-table-column>
          <el-table-column prop="loginNum" :label="$t('i18nn_6c4ee02a7ce0e0be')">
          </el-table-column>
          <el-table-column prop="roleName" :label="$t('i18nn_e40dab1482ecbe86')">
          </el-table-column>
          <!-- <el-table-column prop="email" label="Email">
          </el-table-column>
          <el-table-column prop="enable" :label="$t('6cdece641436d7ab')" :formatter="formatterEnable">
            <template slot-scope="scope">
              <el-tag v-if="'1'===scope.row.enable">{{$t('i18nn_889b3ef0590d9f54')}}</el-tag>
              <el-tag v-else-if="'0'===scope.row.enable" type="info">{{$t('i18nn_11419332713360d2')}}</el-tag>
              <el-tag v-else type="info">{{scope.row.enable}}</el-tag>
            </template>
          </el-table-column> -->
          
          <el-table-column :label="$t('93f5ca01b006206c')" width="200px">
            <template slot-scope="scope">
              <div v-if="1!=scope.row.userId">
                <el-button @click="toPageNameParm('PermConfAdminAdd',scope.row)" type="text" size="mini">{{$t('c09a4ec2fe473b0a')}}</el-button>
                <el-button @click="delPageAction(scope.row)" type="text" size="mini">{{$t('e33c9b93c36fd250')}}</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="tableConPagination">
        <hy-page-pagination :pagination='pagination' v-on:updatePageData="getPageData"></hy-page-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {

  // name: 'HomeConfFrame',
  //meta信息seo用
  // metaInfo: {
  //   title: '互易天下-管理后台-基础配置'
  // },
  data() {
    return {
			loading_load:false,
      //表格数据
      //loading,表格数据
      loading: false,
      //表格数据
      tableData: [],
      //分页数据
      pagination: this.$Utils.defaultPagination(),

      //查询，排序方式
      filterData: {
        "orderBy": "id_", //排序字段
        "sortAsc": "desc", //desc降序，asc升序
      },
      
    }
  },
	activated() {
		this.initData();
	},
    //创建时
  created() {
    // this.initData()
  },
  //编译挂载前
  mounted() {
    
    // console.log("mounted");
    
  },
  methods: {
    initData(){
      this.getPageData();
      
    },
    //直接打开页面
    toPageName(name){
      this.$router.push({name: name});
    },
    //打开新增编辑
    toPageNameParm(name,formParm) {

      if(null === formParm){
        this.$router.push({name:name});
      } else {
        this.$router.push({name:name,query:{userId:formParm.userId}});
      }

    },
    //状态
    // formatterEnable(row, column){
    //   if('1'===row.enable){
    //     return this.$t('i18nn_889b3ef0590d9f54');
    //   } else if('0'===row.enable){
    //     return this.$t('4e9fc68608c60999');
    //   } else {
    //     return row.enable;
    //   }
    // },
    //请求分页数据
    getPageData() {
      let _this = this;
      this.loading_load = true;
      this.$http.post(this.$urlConfig.HyAdminPage, {
          "sortAsc": this.filterData.sortAsc,
          "orderBy": this.filterData.orderBy,
          "offset": (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
          "limit": this.pagination.page_size, //当前页显示数目
        })
        .then(({ data }) => {
          console.log("用户列表，请求成功");
          console.log(data);
          
          this.loading_load = false;
          if(200==data.code){
            //表格显示数据
            this.tableData = data.rows;
            //当前数据总条数
            this.pagination.total = parseInt(data.total);
            //当前页数
            // this.pagination.current_page = parseInt(data.current);
            //当前页条数
            // this.pagination.page_size = parseInt(data.size);
            // this.$message.success('用户列表，请求成功');
          } else {
            this.$message.warning(data.msg?data.msg:this.$t('hytxs0000029'));
          }
          
        })
        .catch((error) => {
          console.log(error);
          console.log("用户列表，请求失败");
          this.$message.error('列表数据，请求失败！');
          this.loading_load = false;
        });
    },
    
    //删除操作
    delPageAction(dataParm){
      this.$confirm(this.$t('i18nn_18a47a2171c01d1c')+dataParm.userName+'吗?', this.$t('daaaeb1b7b22b126'), {
          confirmButtonText: this.$t('204ffab8a6e01870'),
          cancelButtonText: this.$t('4b3340f7872b411f'),
          type: 'warning'
        }).then(() => {
          this.delPageData(dataParm);
        }).catch(() => {
                   
        });
    },
    //删除特定数据
    delPageData(dataParm) {
      let _this = this;
      console.log(dataParm);
      this.loading_load = true;
      this.$http.post(this.$urlConfig.HyAdminDel, {userId:dataParm.userId})
        .then(({ data }) => {
          console.log("删除，请求成功");
          console.log(data);
          this.loading_load = false;
          if (200 == data.code) {
            this.$alert('恭喜,删除成功！', this.$t('cc62f4bf31d661e3'), {
              type: 'success',
              confirmButtonText: this.$t('204ffab8a6e01870'),
            });
            this.getPageData();
          } else {
            // if (!data.msg) {
            //   data.msg = "删除失败,请重试";
            // }
            this.$alert(data.msg?data.msg:"删除失败,请重试", this.$t('cc62f4bf31d661e3'), {
              type: 'warning',
              confirmButtonText: this.$t('204ffab8a6e01870'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("删除，请求失败");
          this.loading_load = false;
          this.$alert('删除失败,请重试！', this.$t('cc62f4bf31d661e3'), {
            type: 'warning',
            confirmButtonText: this.$t('204ffab8a6e01870'),
          });
        });
    },
    

  }
}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }*/

</style>
